import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './App.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home/Home";
// import Ads from "./pages/Ads/Ads";
import Contact from "./pages/Contact/Contact";
import TermsOfService from "./pages/Legal/CGU";
import Privacy from "./pages/Legal/Privacy";
import Countdown from "./components/Countdown/Countdown";
import Error404 from "./pages/Error/Error404";
import Error500 from "./pages/Error/Error500";


const App: React.FC = () => (

    <BrowserRouter>
        <Routes>
            <Route
                path="/"
                element={
                    <>
                        <Countdown/>
                        <Header />
                        <Home />
                        <Footer />
                    </>
                }
            />

            <Route
                path="/contact"
                element={
                    <>
                        <Countdown/>
                        <Header />
                        <Contact />
                        <Footer />
                    </>
                }
            />

            {/*
            <Route
              path="/ads"
              element={
                <>
                  <Header />
                  <Ads />
                  <Footer />
                </>
              }
            />
            */}

            <Route path="/CGU" element={<TermsOfService />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/500" element={<Error500 />} />
            <Route path="*" element={<Error404/>} />
        </Routes>
    </BrowserRouter>
);

export default App;
