import React from 'react';
import './Home.scss';
import main from '../../assets/main.webp';

const Home: React.FC = () => {

    return(
        <div className="main">
            <div className="content">
                <div className="left">
                    <h1>
                        Qui est derrière<br/><strong>TOUT CA ?</strong>
                    </h1>
                    <h4>L'application pour les lycéens</h4>
                    <div className="buttons">
                        <p>Télécharger sur </p>
                        <a href="https://apps.apple.com/fr/app/riddle/id6480002276" target="_blank">
                            <button>
                                <svg width="17" height="20" viewBox="0 0 17 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1282_1140)">
                                        <path
                                            d="M15.843 6.818C15.7264 6.908 13.6679 8.062 13.6679 10.628C13.6679 13.596 16.2872 14.646 16.3656 14.672C16.3536 14.736 15.9495 16.11 14.9846 17.51C14.1242 18.742 13.2256 19.972 11.8586 19.972C10.4916 19.972 10.1398 19.182 8.56176 19.182C7.0239 19.182 6.47711 19.998 5.22671 19.998C3.97632 19.998 3.10386 18.858 2.10074 17.458C0.938798 15.814 0 13.26 0 10.836C0 6.948 2.54099 4.886 5.04177 4.886C6.37056 4.886 7.47822 5.754 8.31249 5.754C9.10654 5.754 10.3449 4.834 11.8566 4.834C12.4295 4.834 14.488 4.886 15.843 6.818ZM11.1389 3.188C11.7641 2.45 12.2064 1.426 12.2064 0.402C12.2064 0.26 12.1943 0.116 12.1682 0C11.151 0.038 9.94081 0.674 9.21108 1.516C8.63815 2.164 8.10342 3.188 8.10342 4.226C8.10342 4.382 8.12955 4.538 8.14161 4.588C8.20594 4.6 8.31048 4.614 8.41501 4.614C9.32767 4.614 10.4755 4.006 11.1389 3.188Z"
                                            fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1282_1140">
                                            <rect width="16.3636" height="20" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.riddle.app" target="_blank">
                            <button>
                                <svg width="36" height="20" viewBox="0 0 36 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1282_1143)">
                                        <path
                                            d="M26.8425 6.04397L29.7926 0.93361C29.9591 0.647121 29.8584 0.283199 29.5758 0.116724C29.2932 -0.0497471 28.9254 0.0470368 28.7628 0.333527L25.774 5.5097C23.4937 4.46827 20.9269 3.88754 18.1665 3.88754C15.4061 3.88754 12.8393 4.46826 10.559 5.5097L7.57024 0.333527C7.40377 0.0470368 7.03985 -0.0497471 6.75336 0.116724C6.46687 0.283199 6.37008 0.647121 6.53655 0.93361L9.48662 6.04397C4.39949 8.80047 0.953861 13.9495 0.384766 19.9774H35.9483C35.3792 13.9495 31.9335 8.80042 26.8425 6.04397ZM10.0015 14.9832C9.17691 14.9832 8.51101 14.3135 8.51101 13.4927C8.51101 12.6681 9.18078 12.0022 10.0015 12.0022C10.8262 12.0022 11.4921 12.6719 11.4921 13.4927C11.4959 14.3135 10.8262 14.9832 10.0015 14.9832ZM26.3276 14.9832C25.503 14.9832 24.8371 14.3135 24.8371 13.4927C24.8371 12.6681 25.5069 12.0022 26.3276 12.0022C27.1523 12.0022 27.8182 12.6719 27.8182 13.4927C27.822 14.3135 27.1523 14.9832 26.3276 14.9832Z"
                                            fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1282_1143">
                                            <rect width="35.5872" height="20" fill="white"
                                                  transform="translate(0.363281)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </a>
                    </div>
                </div>
                <div className="right">
                    <img src={main} alt="Application Riddle" loading="lazy"/>
                </div>
            </div>
        </div>
    );
}


export default Home;