import React from 'react';
import './Contact.scss';

const Contact: React.FC = () => (
    <div className="main">
        <div className="contact-form">
            <h1>Besoin d’aide ?</h1>
            <form>
                <div className="form-group">
                    <input type="text" id="prenom" name="prenom" placeholder="Prénom"/>
                    <input type="text" id="nom" name="nom" placeholder="Nom"/>
                </div>
                <div className="form-group">
                    <input type="email" id="email" name="email" placeholder="Email"/>
                </div>
                <div className="form-group">
                    <textarea id="message" name="message" placeholder="Ton message"></textarea>
                </div>
                <button style={{opacity: 0.6}} type="submit">Indisponible pour le moment...</button>
            </form>
        </div>
    </div>
);

export default Contact;