import React from 'react';
import './Legal.scss';
import logo from "../../assets/logo.svg";
import {ChevronLeft, Mail} from "lucide-react";
import {Link} from "react-router-dom";

const Privacy: React.FC = () => (
    <div className="view">
        <div className="top">
            <Link to="/">
                <ChevronLeft color="white" size={40} strokeWidth={2}/>
            </Link>
            <img src={logo} alt="Logo Riddle"/>
            <h1>Politique de Confidentialité</h1>
        </div>

        <div className="content">
            <section>
                <h2>1. Introduction</h2>
                <p>
                    Nous nous engageons à protéger votre vie privée. Cette politique de confidentialité explique comment
                    nous collectons, utilisons, stockons et partageons vos données personnelles.
                </p>
            </section>

            <section>
            <h2>2. Collecte des Données</h2>
                <p>
                    Nous collectons les informations que vous nous fournissez lorsque vous vous inscrivez sur notre
                    réseau social. Ces informations peuvent inclure votre nom, votre adresse e-mail, votre photo de
                    profil, ainsi que toute autre information que vous choisissez de partager avec nous.
                </p>
            </section>

            <section>
                <h2>3. Utilisation des Données</h2>
                <p>
                    Vos données sont utilisées pour :
                </p>
                <ul>
                    <li>Personnaliser votre expérience sur notre réseau social.</li>
                    <li>Vous fournir un service client efficace.</li>
                    <li>Vous envoyer des notifications pertinentes concernant les mises à jour ou les nouvelles
                        fonctionnalités de notre plateforme.
                    </li>
                </ul>
            </section>

            <section>
                <h2>4. Sécurité des Données</h2>
                <p>
                    Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données contre les accès
                    non autorisés, les pertes ou les fuites. Nous utilisons des technologies de cryptage et des
                    contrôles d'accès rigoureux pour garantir la sécurité de vos informations.
                </p>
            </section>

            <section>
                <h2>5. Droits des Utilisateurs</h2>
                <p>
                    En vertu du RGPD, vous avez le droit :
                </p>
                <ul>
                    <li>D'accéder à vos données personnelles.</li>
                    <li>De les corriger ou de les mettre à jour.</li>
                    <li>De demander la suppression de vos données.</li>
                    <li>De demander la portabilité de vos données.</li>
                </ul>
                <p>
                    Pour exercer ces droits, veuillez nous contacter à l'adresse suivante :
                </p>
                <a href="mailto:admin@app-riddle.com">
                    <Mail color="#885CF4" size={24} strokeWidth={2}/>
                    admin@app-riddle.com
                </a>
            </section>

            <section>
                <h2>6. Modifications de la Politique</h2>
                <p>
                    Nous pouvons mettre à jour cette politique de confidentialité de temps en temps pour refléter les
                    changements dans nos pratiques ou dans la législation applicable. Nous vous informerons de tout
                    changement en publiant la nouvelle version sur notre site. Nous vous encourageons à consulter
                    régulièrement cette politique pour rester informé des modifications.
                </p>
            </section>

            <section>
                <h2>7. Contact</h2>
                <p>
                    Pour toute question ou demande concernant cette politique de confidentialité ou l'utilisation de vos
                    données personnelles, veuillez nous contacter à l'adresse suivante :
                </p>
                <a href="mailto:admin@app-riddle.com">
                    <Mail color="#885CF4" size={24} strokeWidth={2}/>
                    admin@app-riddle.com
                </a>
                <p>
                    Nous nous engageons à répondre à vos
                    demandes dans les plus brefs délais.
                </p>
            </section>
        </div>
    </div>
);

export default Privacy;